<template>
  <div class="wrap">
    <div class="main">
      <div class="box" v-for="item in taocan_data" :key="item.id">
        <div class="title">{{ item.mc }}</div>
        <div class="desc">
          <div class="info">
            <div class="label">
              <img src="@/assets/img/zzjf.png" alt="" class="icon">
              <span>{{ item.shoujia }}</span>
            </div>
            <div class="label">
              <img src="@/assets/img/fwlx.png" alt="" class="icon">
              <span v-if="item.type == '1'">{{ item.yxqx }}天</span>
              <span v-if="item.type == '2'">{{ item.xfcs }}次</span>
            </div>
          </div>
          <div class="btn">
            <VanButton type="primary" block size="small" @click="tapSubmit(item)" :loading="item.loading">下单</VanButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { recordExpression } from '@babel/types';
import { showConfirmDialog, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Fwtc',
  data() {
    return {
    }
  },
  created() {
    this.get_taocan_data({
      sfbs:this.$route.query.sfbs
    });
  },
  computed: {
    ...mapState(['taocan_data']),
  },
  methods: {
    ...mapActions(['get_taocan_data', 'taocan_order']),
    async tapSubmit(record) {
      showConfirmDialog({
        title: '提示',
        message: `是否确认购买[${record.mc}]服务?`,
        confirmButtonText: '确定'
      }).then(async _ => {
        record.loading = true;
        let { data: res } = await this.taocan_order({
          sjhm: this.$route.query.sjhm,
          sfbs: this.$route.query.sfbs,
          taocan_id: record.id
        });
        record.loading = false;
        if (res.success) {
          showNotify({ type: 'success', message: res.msg || '操作成功' });
        }
        else {
          showNotify({ type: 'danger', message: res.msg || '操作失败' })
        }
      });

    }
  },

}
</script>
<style lang="less" scoped>
.wrap {
  .main {
    margin-top: 24px;

    .box {
      margin: 12px;
      padding: 12px;
      background: #fff;
      border-radius: 10px;

      .title {
        font-size: 20px;
        color: #333;
        margin-bottom: 6px;
      }

      .desc {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info {
          display: flex;
          align-items: center;
          flex: 1;

          .label {
            display: flex;
            align-items: center;
            margin-right: 24px;

            .icon {
              width: 24px;
              height: 24px;
              margin-right: 6px;
            }

            span {
              font-size: 18px;
              color: #3fb6c6;
            }
          }
        }

        .btn {
          width: 80px;
        }

      }
    }
  }
}
</style>
